import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FaSearch, FaMoneyBillWave } from 'react-icons/fa'
import { serverItemShopImageStorage } from '../../helpers/APIConfig' 
import { Badge } from 'react-bootstrap'

const ItemViewModal = (props) => {

    return (
        <Modal
        onHide={props.onHide}
        show={props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton style={{ backgroundColor: "#383b3d"}}>
                <Modal.Title id="contained-modal-title-vcenter" className="text-white">
                    <FaSearch /> Viewing {props.item.itemName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#212529"}}>
                <div className="text-center">
                    {
                        props.item.itemIco == null && <img src={`${serverItemShopImageStorage}default.png`} alt={props.item.itemName} className="img-fluid"/>
                    }
                    {
                        props.item.itemIco != null && <img src={`${serverItemShopImageStorage}${props.item.itemIco}`} alt={props.item.itemName} className="img-fluid"/>
                    }
                </div>
                <div className="price" style={{marginBottom:"5px"}}>
                        {
                             props.item.itemSec === 1 && <div><Badge bg="info" text="dark"><b>{props.item.itemPrice}</b> {props.item.itemSecFormat}</Badge>&nbsp;</div>
                        }
                        {
                            props.item.itemSec === 2 && <div><Badge bg="warning" text="dark"><b>{props.item.itemPrice}</b> {props.item.itemSecFormat}</Badge>&nbsp;</div>
                         }
                 </div>
                 <span><b><i>{props.item.itemComment} </i></b></span>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "#383b3d"}}>
                <Button variant="danger" onClick={props.onBuyItem}><FaMoneyBillWave /> Buy</Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ItemViewModal
