import React from 'react';
import { Container } from 'reactstrap';
import Footer from './Footer';
import NavMenu from './NavMenu';
import { Row } from 'reactstrap';
import ClubWars from './ClubWars';
import IndividualRank from './IndividualRank';
import Login from './Login';
import Facebook from './Facebook';
import UserPanel from '../components/user/UserPanel'
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import SquareAdPreview from './adSense/SquareAdPreview';
import HorizontalAdPreview from './adSense/HorizontalAdPreview';


const Layout = ( props ) => {

    let location = useLocation();
    let items = [];
    
    if(props.isAuthenticated === true){
        items.push(<UserPanel isAuthenticated={props.isAuthenticated} setAuthentication={props.setAuthentication} key="intro1"/> )
    }else{
        items.push(<Login  setAuthentication={props.setAuthentication} key="intro2"/>)
    }

    return (
        <div>
            {
                props.isAuthenticated === false && (location.pathname === '/account' || location.pathname === '/change-password' 
                || location.pathname === '/change-pin' || location.pathname === '/change-email' || location.pathname === '/gametime-gamepoints' 
                || location.pathname === '/topup/records' || location.pathname === '/forgot-pin' || location.pathname === '/shop/records'
                || location.pathname === '/pilot-mode'
                || location.pathname === '/ip-whitelists'
                || location.pathname === '/shop'
                // || location.pathname === '/helpdesk'
                // || location.pathname === '/helpdesk/create'
                ) &&
                    <Redirect to="/" />
            }
            <NavMenu isAuthenticated={props.isAuthenticated}/>
            <Container className="main-container">
                {
                    location.pathname === '/topup/records' || location.pathname === '/shop/records' ? 
                    (
                        <>
                            <Row>
                                <div className="col-md-12">
                                    { props.children }
                                </div>
                            </Row>
                        </>
                    )
                    :
                    (<Row>
                        <div className="col-md-3">
                            {items}
                            <SquareAdPreview />
                            <IndividualRank />
                            <ClubWars />    
                            <SquareAdPreview />
                        </div>
                        <div className="col-md-9">
                            { props.children }
                            <HorizontalAdPreview />
                            <Facebook />
                            <HorizontalAdPreview />
                        </div>
                    </Row>)
                }
            </Container>
            <Footer />
        </div>
    )
}

export default Layout

