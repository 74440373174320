import React from 'react'
import Card from 'react-bootstrap/Card'
import { useState, useEffect } from 'react'
import { Badge, Alert, Button } from 'react-bootstrap'
import { getShopItems, shopBuyItem } from '../helpers/APIHelper'
import Bubbles from './loading/Bubbles';
import { serverItemShopImageStorage } from '../helpers/APIConfig'
import { FaSearch, FaMoneyBillWave } from 'react-icons/fa'
import ItemViewModal from './modals/ItemViewModal'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'
import ErrorModal from './modals/ErrorModal'
import $ from 'jquery'
import PaginationButton from './PaginationButton'


const Shop = ( {setAuthentication} ) => {
    
    const [modalShow, setModalShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [buyingData, setBuyingData] = useState({})
    const [viewModalShow, setViewModalShow] = React.useState(false);

    const [pageTitle, setPageTitle] = useState('SHOP')
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({
        data : {}
    })

    const [pageNumber, setPageNumber] = useState(1)

    const [paramFilter, setParamFilter] = useState({
        type: 0,
        category: 0,
        typeId : 0,
        categoryId: 0
    })

    const updatePageTitle = () =>{
        let defaultTitle = 'SHOP';
        let selectedCategory = '';

        var param = { ...paramFilter }

       if(param.type === 0  || param.category === 0){
           if(param.type !== 0){
            defaultTitle = param.type;
           }else{
            if(param.category !== 0){
                selectedCategory = param.category;
            }

            if(selectedCategory !== '')
                defaultTitle = defaultTitle + ' - ' +  selectedCategory;
           }
       }
       else{
            if(param.category !== 0){
                    selectedCategory = param.category;
             }
            if(selectedCategory !== '')
                defaultTitle = param.type + ' - ' +  selectedCategory;
            else
                defaultTitle = param.type;
       }
        setPageTitle(defaultTitle);
    }

    useEffect(() => {
        async function fetchData() {
            await fetchInitialData();
        }

        fetchData();
        updatePageTitle();
    }, [paramFilter, pageNumber]);
    
    const fetchInitialData = async () => {
        setLoading(true)
        var result = await getShopItems(paramFilter, pageNumber, setAuthentication);
        if(result !== null) {
            if(result.status === 200) {
                setData(result.data)
                setLoading(false)
            }
        }
    }

    const [filterTypes, setFilterType] = useState([
        {
            id: 1,
            type: "gameTime",
            text: "Game Time Shop",
            isSelected: false,
            btnColor: "info"
        },
        {
            id: 2,
            type : "premium",
            text: "Premium Shop",
            isSelected: false,
            btnColor: "warning"
        }
    ]);

    const [filterCategories, setFilterCategory] = useState([
        {
            id: 0,
            type: "all",
            text: "All",
            isSelected: false
        },
        {
            id: 1,
            type: "head",
            text: "Head",
            isSelected: false
        },
        {
            id: 2,
            type : "coat",
            text: "Coat",
            isSelected: false
        },
        {
            id: 3,
            type : "pants",
            text: "Pants",
            isSelected: false
        },
        {
            id: 4,
            type : "gloves",
            text: "Gloves",
            isSelected: false
        },
        {
            id: 5,
            type: "shoes",
            text: "Shoes",
            isSelected: false
        },
        {
            id: 6,
            type : "weapon",
            text: "Weapon",
            isSelected: false
        },
        {
            id: 7,
            type : "necklace",
            text: "Necklace",
            isSelected: false
        },
        {
            id: 8,
            type : "bracelet",
            text: "Bracelet",
            isSelected: false
        },
        {
            id: 9,
            type : "ring",
            text: "Ring",
            isSelected: false
        },
        {
            id: 10,
            type : "etc",
            text: "ETC",
            isSelected: false
        },
        {
            id: 11,
            type : "giftBox",
            text: "Gift Box",
            isSelected: false
        },
        {
            id: 12,
            type : "costumes",
            text: "Costumes",
            isSelected: false
        }
    ]);

    const selectUnselectFilter = async (id, type, state, data) => {
        let unSelectFilter = data.map((filter) => filter.id !== id ? { ...filter, isSelected: false } : filter)
        state(unSelectFilter);

        state(unSelectFilter.map((filter) => filter.id === id ? { ...filter, isSelected: true } : filter))
        
        var param = { ...paramFilter }
        setPageNumber(1);

        if(data === filterTypes) {
            param.type = type;
            param.typeId = id;
            setParamFilter(param)
        }
        if(data === filterCategories) {
            param.category = type;
            param.categoryId = id;
            setParamFilter(param)
        }
    }

    const onViewItem = (item) => {
        if(item === null || item === undefined) return;
        setBuyingData(item)
        setViewModalShow(true)
    }

    const onBuyItem = async (item) =>  {

        if(item === null || item === undefined) return;

        setViewModalShow(false)
        setBuyingData(item)

        confirmAlert({
            title: 'Confirm Buy Item',
            message: 'Are you sure you want to buy ' + item.itemName + '?', 
            buttons: [
              {
                label: 'Yes',
                onClick: async () => onProcessBuyItem(item)
              },
              {
                label: 'No'
              }
            ]
          });
    }

    const onProcessBuyItem =  async (item) => {
        
        if(item === null || item === undefined) return;

        var result = await shopBuyItem({ id: item.productNum }, setAuthentication);
        if(result !== null || result !== undefined){
            if(result.success) {
                setUserBasicInfo(result.data.premiumPoints, result.data.gamePoints)

                confirmAlert({
                    title: 'Success',
                    message: 'You can now use the item just go to your Account (B) Bank ingame.', 
                    buttons: [
                      {
                        label: 'Okay'
                      }
                    ]
                  });
            }else{
                // not found or bad request.
                if(result.status === 404 || result.status === 400){
                    setModalShow(true)
                    setErrors(result.errors)
                 }   // do nothing if unauthorize.
              }
         }
    }

    const setUserBasicInfo = (premiumPoints, gamePoints) => {
        $('#premiumPoints').html(premiumPoints) 
        $('#gamePoints').html(gamePoints) 
    } 


    return (
       <Card className="ran-card">
            <Card.Header className="ran-card-header">{pageTitle}</Card.Header>
            <Card.Body>
                <div className="text-center mb-2">
                    <div className="mb-2">
                        <Badge bg="warning" text="dark">TYPE</Badge>
                    </div>
                    <div className="btn-group rankings-btn-group btn-group-sm mb-4" role="group">
                        {
                            filterTypes.map((filterType, index) =>
                                <button key={index} onClick={() => selectUnselectFilter(filterType.id, filterType.text, setFilterType, filterTypes)}  type="button" className={`text-${filterType.id === 1 ? 'white': 'dark'} btn btn-${filterType.btnColor}`}> {filterType.text} </button>
                        )}
                    </div>
                    <div className="mb-2">
                        <Badge bg="info" text="white">CATEGORY</Badge>
                    </div>
                    <div className="d-block btn-group rankings-btn-group btn-group-sm mb-4" role="group">
                        {
                            filterCategories.map((filterCategory, index) =>
                                <button key={index} onClick={() => selectUnselectFilter(filterCategory.id, filterCategory.type, setFilterCategory, filterCategories)}  type="button" className={`btn btn-sm btn-${filterCategory.isSelected ? 'danger': 'primary'}`}> {filterCategory.text} </button>
                        )}
                    </div>
                </div>
                <div>
                    {
                        isLoading ? 
                        (
                           <Bubbles />
                        )
                        : data.dataList.length > 0 ? (<>
                            <div className="clearfix"></div>
                            <div className="row items"> 
                                { data.dataList.map((data, index) => 
                                    <div key={index} className="col-md-6">
                                        <div className="shop-item">
                                            {
                                                data.itemIco === null && <img src={`${serverItemShopImageStorage}default.png`} alt={data.itemName} />
                                            }
                                            {
                                                data.itemIco != null && <img src={`${serverItemShopImageStorage}${data.itemIco}`} alt={data.itemName} />
                                            }
                                            <div className="item-data">
                                                <span className="title">{data.itemName}</span>
                                                <br />
                                                <div className="price">
                                                    {
                                                        data.itemSec === 1 && <div><Badge bg="info" text="dark"><b>{data.itemPrice}</b> {data.itemSecFormat}</Badge>&nbsp;</div>
                                                    }
                                                    {
                                                        data.itemSec === 2 && <div><Badge bg="warning" text="dark"><b>{data.itemPrice}</b> {data.itemSecFormat}</Badge>&nbsp;</div>
                                                    }
                                                </div>
                                                <div className="action">
                                                    <div className="row" style={{ marginRight: "10px"}}>
                                                        <Button onClick={() => onViewItem(data)} size="sm" variant="primary" style={{ marginBottom: "5px"}}><FaSearch /> View</Button>
                                                        <Button onClick={() => onBuyItem(data)} size="sm" variant="danger"><FaMoneyBillWave /> Buy</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <PaginationButton data={data} setPageNumber={setPageNumber} />

                            <ItemViewModal
                                            item={buyingData}
                                            show={viewModalShow}
                                            onBuyItem={() => onBuyItem(buyingData)}
                                            onHide={() => setViewModalShow(false)} />
                            <ErrorModal
                                errors={errors}
                                show={modalShow}
                                onHide={() => setModalShow(false)}/>
                         </>)
                         : 
                         (
                            <Alert variant="info">
                                <span className="text-dark">No items posted at this category.</span>
                            </Alert>
                         )
                    }
                </div>
            </Card.Body>
        </Card>
    )
}

export default Shop
